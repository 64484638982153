import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
// mui components
import { Grid, Tabs, Tab, Box, TabProps, Button, ButtonProps, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
// sections
import AdminSection from './sections/admin-section';
import DealsSection from './sections/deals-section';
import ProfileSection from './sections/profile-section';
import EducationSection from './sections/education-section';
import AnalyticsSection from './sections/analytics-section';
import ReferralSection from './sections/referral-section';
import DealDetailsSection from './sections/deal-details-section';
// custom hooks
import { useUserData } from 'hooks/useUserData';
// icons
import { ReactComponent as DealIcon } from 'assets/icons/deal_2.svg';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/education.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as ReferralIcon } from 'assets/icons/referral.svg';
import { ReactComponent as AdminIcon } from 'assets/icons/admin.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/log-out.svg';
// styles
import { colors } from 'styles/colors';
import ViewCourse from './sections/education-section/view-course';
import usePageTimeTracker from 'hooks/usePageTimeTracker';

const timeTrackerBuckets = {
  timer_1m: 60,
  timer_2m: 120,
  timer_5m: 300,
  timer_10m: 600,
};

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  activeValue: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, activeValue, ...other } = props;

  return (
    <Box
      display="flex"
      position="relative"
      width={value !== activeValue ? '0' : '100%'}
      role="tabpanel"
      hidden={value !== activeValue}
      id={`vertical-tabpanel-${activeValue}`}
      aria-labelledby={`vertical-tab-${activeValue}`}
      {...other}
    >
      {value === activeValue && children}
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '218px',
  paddingTop: '40px',
  backgroundColor: colors.main.black,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.colors.main.yellow,
    left: 0,
    right: 'unset',
  },
}));

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  justifyContent: 'flex-start',
  minHeight: '56px',
  marginBottom: '16px',
  padding: '12px 27px',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.placeholder,
  textTransform: 'unset',
  '&.Mui-selected': {
    color: theme.colors.main.white,
    '& path': {
      fill: theme.colors.main.white,
    },
  },
  '& .MuiTab-iconWrapper': {
    marginRight: '16px',
  },
}));

const StyledButton = styled(({ className, onClick, children }: ButtonProps) => (
  <Button
    disableRipple
    className={className}
    startIcon={<LogOutIcon />}
    onClick={onClick}
    children={children}
  />
))(({ theme }) => ({
  justifyContent: 'flex-start',
  minHeight: '56px',
  marginBottom: '16px',
  padding: '12px 27px',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.placeholder,
  textTransform: 'unset',
  '& .MuiButton-startIcon': {
    marginRight: '16px',
  },
}));

const Profile: React.FC = () => {
  const history = useHistory();

  const { logOut, permissionsData } = useUserData();
  const [value, setValue] = useState<string>('deals');

  usePageTimeTracker(value, timeTrackerBuckets);

  useEffect(() => {
    const [, pageName] = history.location.pathname.split('/');
    setValue(pageName);
  }, [history]);

  const handleChangeTab = useCallback(
    (e: SyntheticEvent, page: string) => {
      setValue(page);
      history.replace({ pathname: `/${page}` });
    },
    [history]
  );

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      sx={{ minHeight: 'calc(100vh - 72px)', backgroundColor: '#1A0034' }}
    >
      <Grid
        container
        wrap="nowrap"
        flexGrow={1}
        sx={{ maxWidth: '1150px', backgroundColor: colors.main.white }}
      >
        <Hidden smDown>
          <StyledTabs
            value={value}
            variant="scrollable"
            orientation="vertical"
            onChange={handleChangeTab}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <StyledTab label="Deals" icon={<DealIcon />} iconPosition="start" value="deals" />
            <StyledTab label="Profile" icon={<MusicIcon />} iconPosition="start" value="profile" />
            {permissionsData.education && (
              <StyledTab
                label="Education"
                icon={<EducationIcon />}
                iconPosition="start"
                value="education"
                onClick={() => {
                  if (window.location.pathname.trim() !== '/education') {
                    window.location.href = '/education';
                  }
                }}
              />
            )}

            {permissionsData.analytics && (
              <StyledTab
                label="Analytics"
                icon={<AnalyticsIcon />}
                iconPosition="start"
                value="analytics"
              />
            )}

            <StyledTab
              label="Referral"
              icon={<ReferralIcon />}
              iconPosition="start"
              value="referral"
            />

            {permissionsData.admin && (
              <StyledTab label="Admin" icon={<AdminIcon />} iconPosition="start" value="admin" />
            )}

            <StyledButton onClick={logOut}>Log out</StyledButton>
          </StyledTabs>
        </Hidden>

        <TabPanel value="dashboard" activeValue="dashboard">
          <Switch>
            <Route exact path="/deals" component={DealsSection} />
            <Route exact path="/deals/:id" component={DealDetailsSection} />
            <Route exact path="/profile" component={ProfileSection} />
            <Route exact path="/education" component={EducationSection} />
            <Route exact path="/education/:slug" component={ViewCourse} />
            <Route exact path="/analytics" component={AnalyticsSection} />
            <Route exact path="/referral" component={ReferralSection} />
            <Route exact path="/admin" component={AdminSection} />
          </Switch>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Profile;
