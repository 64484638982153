import { Box, MenuItem, styled } from '@mui/material';
import Text from 'components/text';
import { useEffect, useState } from 'react';
import api from 'utils/api';

import { useError } from 'hooks/useError';

import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { emailRule } from 'utils/validation-rules';
import * as yup from 'yup';

import BaseInput from 'components/inputs/base-input';
import PurpleButton from 'components/purple-button';
import BaseInputController from 'controllers/base-input-controller';
import CheckboxController from 'controllers/checkbox-controller';
import copyToClipboard from 'utils/copyToClipboard';
import ArtistInputController from '../../../../controllers/artist-input-controller';

const commonSchema = {
  artistStatus: yup.string().required('Field is required'),
  noSpotifyId: yup.boolean(),
  spotifyId: yup
    .string()
    .nullable()
    .when('noSpotifyId', {
      is: false,
      then: yup.string().nullable().required('Field is required'),
    }),
  artistName: yup.string().nullable().required('Field is required'),
  youtubeId: yup
    .string()
    .nullable()
    .when('noSpotifyId', {
      is: true,
      then: yup.string().nullable().required('Field is required'),
    }),
  email: emailRule,
  // password: yup.string().required('Field is required'),
};

const signedArtistsInviteSchema = yup.object({
  ...commonSchema,
  advanceAmount: yup.number().required('Field is required'),
  termLength: yup.number().required('Field is required'),
  distributionStatus: yup.string().required('Field is required'),
  hasAnalyticsAccess: yup.boolean().required('Field is required'),
  hasEducationAccess: yup.boolean().required('Field is required'),
});

const unsignedArtistsInviteSchema = yup.object({
  ...commonSchema,
});

const StyledButton = styled(PurpleButton)(() => ({
  margin: '8px 0px',
}));

export default function NewInviteForm() {
  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [showSignedForm, setShowSignedForm] = useState(true);
  const [magicLink, setMagicLink] = useState('');

  const form = useForm({
    defaultValues: {
      // password: uid(14),
      artistStatus: 'signed',
    },
    resolver: yupResolver(showSignedForm ? signedArtistsInviteSchema : unsignedArtistsInviteSchema),
  });

  useEffect(() => {
    const { unsubscribe } = form.watch((value) => {
      if (value.artistStatus === 'signed') {
        setShowSignedForm(true);
      } else {
        setShowSignedForm(false);
      }
    });
    return () => unsubscribe();
  }, [form]);

  const Form = () => {
    const prepareData = (formValues: FieldValues) => {
      // const { spotifyId } = formValues;
      // const artistName: string | undefined = artistList.find(
      //   (artist) => artist.value === spotifyId
      // )?.label;

      return formValues.artistStatus === 'signed'
        ? { ...formValues }
        : {
            ...formValues,
            // spotifyId,
            // artistName,
            advanceAmount: 0,
            distributionStatus: 'other',
            hasAnalyticsAccess: false,
            hasEducationAccess: false,
            termLength: 0,
          };
    };

    const onSubmit = (formValues: FieldValues) => {
      api.invitation.createInvitation({ data: prepareData(formValues) }).then((res) => {
        setMagicLink(res.magicLink);
      });
    };

    return (
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column">
            <BaseInputController
              withError
              size="small"
              select
              variant="filled"
              name="artistStatus"
              label="Artist Status"
              defaultValue={form.getValues('artistStatus')}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="signed">Signed</MenuItem>
              <MenuItem value="unsigned">Unsigned</MenuItem>
            </BaseInputController>

            <ArtistInputController withError className="search-artist" />

            <BaseInputController
              withError
              name="email"
              type="email"
              variant="filled"
              label="Artists Email"
            />

            {/* <BaseInputController
              withError
              name="password"
              type="text"
              defaultValue={form.getValues('password')}
              variant="filled"
              label="Account Password"
            /> */}

            {showSignedForm && (
              <>
                <BaseInputController
                  withError
                  name="advanceAmount"
                  type="number"
                  inputMode="numeric"
                  variant="filled"
                  label="Advance Amount"
                />

                <BaseInputController
                  withError
                  name="termLength"
                  type="number"
                  variant="filled"
                  label="Term Length"
                />

                <BaseInputController
                  withError
                  size="small"
                  select
                  variant="filled"
                  name="distributionStatus"
                  label="Distribution Status"
                  defaultValue=""
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="redistributed">Re-distrributed to Snafu</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </BaseInputController>

                <Box display="flex" alignItems="center">
                  <CheckboxController name="hasAnalyticsAccess" />
                  <Text fontSize={17}>Access to Analytics</Text>
                </Box>

                <Box display="flex" alignItems="center" marginBottom={1}>
                  <CheckboxController name="hasEducationAccess" />
                  <Text fontSize={17}>Access to Education</Text>
                </Box>
              </>
            )}

            <Box>
              <StyledButton type="submit">Send Invitation</StyledButton>
            </Box>
          </Box>
        </form>
      </FormProvider>
    );
  };

  const MagicLink = () => {
    return (
      <Box>
        <Box paddingTop={1}>
          <BaseInput
            sx={{
              width: '100%',
            }}
            label="Magic Link"
            value={magicLink}
            variant="filled"
            error={false}
            helperText="Copy and send this link to the artist. It will allow them to login into the SongFund platform. An email will also be sent to the artist with the link."
          />
        </Box>
        <Box marginTop={1}>
          <StyledButton
            onClick={() => {
              copyToClipboard(magicLink);
            }}
            type="submit"
          >
            Copy Magic Link
          </StyledButton>
        </Box>
      </Box>
    );
  };

  return (
    <Box padding={2} maxWidth={500} margin="auto">
      <Box borderBottom={1} borderColor="#eee" paddingBottom={2} marginBottom={2}>
        {magicLink ? (
          <>
            <Text fontSize={26}>Invitation is sent!</Text>
            <Text marginTop={1} lineHeight={1.2}>
              The invitation has been sent to the artist with an email containing the magic link.
            </Text>
          </>
        ) : (
          <>
            <Text fontSize={26}>Invite an artist</Text>
            <Text marginTop={1} lineHeight={1.2}>
              The invitation will create a new account for the artist, allowing access to features
              like Analytics and Education through a magic link or account credentials.
            </Text>
          </>
        )}
      </Box>

      <Box>{magicLink ? <MagicLink /> : <Form />}</Box>
    </Box>
  );
}
