import { yupResolver } from '@hookform/resolvers/yup';
import { Box, debounce } from '@mui/material';
import AutocompleteInputController from 'controllers/autocomplete-input-controller';
import BaseInputController from 'controllers/base-input-controller';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import api from 'utils/api';
import { ISpotifyData } from 'utils/apiTypes';
import { IArtistOption, spotifyArtistsToOptions } from 'utils/helpers';
import { emailRule } from 'utils/validation-rules';
import * as yup from 'yup';
import StyledButton from '../../../auth/components/styled-button';

const referralSchema = yup.object({
  spotifyId: yup.string().nullable().required('Field is required'),
  email: emailRule,
  artistName: yup.string().required('Field is required'),
  note: yup.string(),
});

export default function NewReferralForm({ onSuccess }: { onSuccess: () => void }) {
  interface IFormInputs {
    spotifyId: string | null;
    email: string;
    artistName: string;
    note: string;
  }

  const form = useForm<IFormInputs>({
    defaultValues: {},
    resolver: yupResolver(referralSchema),
  });

  const [artistList, setArtistList] = useState<IArtistOption[]>([]);

  const currentSpotifyId = useWatch({ control: form.control, name: 'spotifyId' });
  const currentArtistName = useWatch({ control: form.control, name: 'artistName' });
  useEffect(() => {
    if (currentArtistName) {
      return;
    }
    const artistName: string | undefined = artistList.find(
      (artist) => artist.value === currentSpotifyId
    )?.label;
    if (artistName) {
      console.log('Set artistName to:', artistName);
      form.setValue('artistName', artistName);
    }
  }, [currentSpotifyId, currentArtistName]);

  const searchArtist = useCallback(async (value: string) => {
    if (value === '') return;
    const data: ISpotifyData = await api.spotify.getSpotifyArtist({ id: value });
    setArtistList(spotifyArtistsToOptions(data));
    form.setValue('artistName', data.name);
  }, []);

  const handleSearchArtist = debounce(searchArtist, 300);

  const prepareData = (formValues: FieldValues) => {
    const { spotifyId } = formValues;
    const artistName: string | undefined = artistList.find(
      (artist) => artist.value === spotifyId
    )?.label;

    return { spotifyId, artistName, ...formValues };
  };

  const onSubmit = (formValues: FieldValues) => {
    api.referral.createReferral({ data: prepareData(formValues) }).then((res) => {
      onSuccess();
    });
  };

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column">
          <AutocompleteInputController
            withError
            name="spotifyId"
            options={artistList}
            label="Search Artist or enter Spotify ID"
            handleChange={handleSearchArtist}
            className="search-artist"
          />

          <BaseInputController
            withError
            name="email"
            type="email"
            variant="filled"
            label="Artist Email"
          />

          <BaseInputController
            withError
            name="artistName"
            type="text"
            variant="filled"
            label="Artist Name"
          />

          <BaseInputController
            withError
            name="note"
            type="text"
            variant="filled"
            label="Note (your connection to artist)"
          />

          <Box>
            <StyledButton type="submit">Send Reference</StyledButton>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
