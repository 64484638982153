export interface ICourse {
  type: string;
  slug: string;
  lessons: number;
  title: string;
  description: string;
}

export const courses = {
  'Social-Media-Best-Practices': {
    type: 'embed',
    slug: 'Social-Media-Best-Practices',
    lessons: 6,
    title: 'Social Media Best Practices',
    description:
      'This course offers concise tips for optimizing content and accounts, addressing frequently asked questions.',
  },
  'Social-Media-Release-Promo': {
    type: 'embed',
    slug: 'Social-Media-Release-Promo',
    lessons: 3,
    title: 'Promote Your Release',
    description:
      'This short course teaches how to promote releases on social media, providing tips for effective content rollout.',
  },
  'spotify-discovery-mode': {
    type: 'embed',
    slug: 'spotify-discovery-mode',
    lessons: 2,
    title: 'Spotify Discovery Mode',
    description: "This short course introduces you to Spotify's Discovery Mode feature.",
  },
  'tiktok-seo-starter-pack': {
    type: 'embed',
    slug: 'tiktok-seo-starter-pack',
    lessons: 5,
    title: 'TikTok SEO Starter Pack',
    description:
      'This short course introduces you to the basics of TikTok SEO with our Starter Pack.',
  },
  'social-media-introduction': {
    type: 'embed',
    slug: 'social-media-introduction',
    lessons: 8,
    title: 'Social Media Course: Introduction',
    description: 'This introductory course provides an overview of social media essentials.',
  },
  'social-media-intermediate': {
    type: 'embed',
    slug: 'social-media-intermediate',
    lessons: 6,
    title: 'Social Media Course: Intermediate',
    description:
      'Enhance your social media skills with this intermediate course on strategies and audience growth.',
  },
  // 'Social-Media-Release-Promo': {
  //   type: 'pdf',
  //   slug: 'Social-Media-Release-Promo',
  //   lessons: 2,
  //   title: 'Promote Your Release',
  //   description:
  //     'This short course teaches how to promote releases on social media, providing tips for effective content rollout.',
  // },
};
