import React, { memo, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// components
import { Box, debounce, TextField } from '@mui/material';
import Text from 'components/text';
import AutocompleteInputController from 'controllers/autocomplete-input-controller';
import CheckboxController from 'controllers/checkbox-controller';
import { useCallback, useState } from 'react';
import api from 'utils/api';
import { ISpotifyData } from 'utils/apiTypes';
import { IArtistOption, spotifyArtistsToOptions } from 'utils/helpers';
import BaseInputController from './base-input-controller';

interface OwnProps {
  spotifyIdName?: string;
  artistFieldName?: string;
  youtubeIdName?: string;
  withError?: boolean;
  [x: string]: any;
}

const ArtistInputController: React.FC<OwnProps> = ({
  spotifyIdName = 'spotifyId',
  youtubeIdName = 'youtubeId',
  artistFieldName = 'artistName',
  withError = false,
  ...props
}) => {
  const [artistList, setArtistList] = useState<IArtistOption[]>([]);
  const { control, watch, setValue } = useFormContext();

  const watchNoSpotifyId = watch('noSpotifyId', false);

  useEffect(() => {
    if (watchNoSpotifyId) {
      setValue(spotifyIdName, null);
      setValue(artistFieldName, null);
    } else {
      setValue(youtubeIdName, null);
      setValue(artistFieldName, null);
    }
  }, [watchNoSpotifyId]);

  useEffect(() => {
    const subscription = watch((value, { name: fieldName, type }) => {
      if (fieldName === spotifyIdName) {
        const artistName: string | undefined = artistList.find(
          (artist) => artist.value === value.spotifyId
        )?.label;
        setValue(artistFieldName, artistName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, artistList, spotifyIdName, setValue, artistFieldName]);

  const searchArtist = useCallback(async (value: string) => {
    if (value === '') return;
    const data: ISpotifyData = await api.spotify.getSpotifyArtist({ id: value });
    const artists = spotifyArtistsToOptions(data);
    console.log('artists', artists);
    setArtistList(artists);
  }, []);

  const handleSearchArtist = debounce(searchArtist, 300);

  return (
    <>
      {!watchNoSpotifyId && (
        <>
          <AutocompleteInputController
            options={artistList}
            name={spotifyIdName}
            withError={withError}
            handleChange={handleSearchArtist}
            label="Search Artist or enter Spotify ID"
            control={control}
            {...props}
          />
        </>
      )}
      {watchNoSpotifyId && (
        <BaseInputController
          withError
          name={youtubeIdName}
          type="text"
          variant="filled"
          label="YouTube URL or ID"
        />
      )}
      <BaseInputController
        withError
        name={artistFieldName}
        type="text"
        label="Artist Name"
        sx={{ ...(watchNoSpotifyId ? {} : { display: 'none' }) }}
      />

      <Box display="flex" alignItems="center" paddingBottom={2} component="label">
        <CheckboxController name="noSpotifyId" />
        <Text fontSize={17}>No Spotify ID?</Text>
      </Box>
    </>
  );
};

export default ArtistInputController;
